import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import Link from 'next/link'
import React from 'react'

import { Container } from '@/components/Container'

export const Footer = () => {
  const year = new Date().getFullYear()

  return (
    <chakra.footer pt="40" pb="4" color="gray.500" fontWeight="bold">
      <Container>
        <Flex align="flex-end">
          <Box fontSize="xs">
            <Text>
              &copy; {year},<chakra.span ml="2">Sharacue.</chakra.span>
            </Text>
            <Text>All rights reserved.</Text>
          </Box>
          <Spacer />
          <Box>
            <Flex>
              <Box fontSize="xs">
                <Link href="/about" prefetch={false}>
                  About
                </Link>
              </Box>
              <Box ml="4" fontSize="xs">
                <Link href="/faq" prefetch={false}>
                  FAQ
                </Link>
              </Box>
            </Flex>
            <Box mt="2" fontSize="xs">
              <Link href="/terms" prefetch={false}>
                Terms & Conditions
              </Link>
            </Box>
          </Box>
        </Flex>
      </Container>
    </chakra.footer>
  )
}
