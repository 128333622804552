export const MAX_ID = 5000

export const SITE_NAME = process.env.NEXT_PUBLIC_SITE_NAME || ''
export const SITE_DESCRIPTION = process.env.NEXT_PUBLIC_SITE_DESCRIPTION || ''
export const SITE_URL = process.env.NEXT_PUBLIC_SITE_URL || ''
export const IPFS_GATEWAY_URL =
  process.env.NEXT_PUBLIC_IPFS_GATEWAY_URL || 'https://ipfs.infura.io/ipfs/'
export const DEPLOY_HASH = process.env.NEXT_PUBLIC_DEPLOY_HASH || ''
export const OPENSEA_ASSETS_URL =
  process.env.NEXT_PUBLIC_OPENSEA_ASSETS_URL || 'https://opensea.io/assets'
export const CHAIN_ID = process.env.NEXT_PUBLIC_CHAIN_ID || ''
export const OPENSEA_COLLECTION_SLUG = process.env.NEXT_PUBLIC_OPENSEA_COLLECTION_SLUG || ''
export const OPENSEA_ASSET_CONTRACT_ADDRESS =
  process.env.NEXT_PUBLIC_OPENSEA_ASSET_CONTRACT_ADDRESS || ''
