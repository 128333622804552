import { Box, Flex } from '@chakra-ui/react'
import React from 'react'

import { Footer } from '@/components/Footer'

type Props = {
  children: React.ReactNode
}

export const Layout = ({ children }: Props) => {
  return (
    <>
      <Flex direction="column" minH="100vh">
        <Box flex="1">
          <main>{children}</main>
        </Box>
        <Footer />
      </Flex>
    </>
  )
}
