import { Box, Flex, Heading, Spacer } from '@chakra-ui/react'
import { chakra } from '@chakra-ui/system'
import NextLink from 'next/link'
import React from 'react'

export const Header = () => {
  return (
    <Flex my={8}>
      <Box>
        <Heading as="h2" size="md">
          <NextLink href="/" prefetch={false}>
            <a>
              Crypto
              <chakra.span color="#f79128" cursor="pointer" fontWeight="bold">
                Ukiyoe
              </chakra.span>
            </a>
          </NextLink>
        </Heading>
      </Box>
      <Spacer />
      <Box fontSize="sm" fontWeight="bold">
        <NextLink href="/collections" prefetch={false}>
          MyCollection
        </NextLink>
      </Box>
    </Flex>
  )
}
