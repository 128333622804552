import { default as NextHead } from 'next/head'
import React from 'react'

import { DEPLOY_HASH, SITE_DESCRIPTION, SITE_NAME, SITE_URL } from '@/lib/constants'
type Props = {
  path: string
  title?: string
  description?: string
  keywords?: string[]
  image?: string
  faviconId?: number
}

export const Head = ({ path, title, description, image, faviconId }: Props) => {
  const defaultTitle = SITE_NAME
  const defaultDescription = SITE_DESCRIPTION
  const siteUrl = SITE_URL
  const url = `${siteUrl}${path}`
  const defaultImage = `${siteUrl}/images/ogp.png`
  const favicon = faviconId
    ? `/images/ukiyoe/b/${faviconId.toString()}.png`
    : '/favicon/favicon.png'
  const ogImage = `${image || defaultImage}?s=${DEPLOY_HASH.substr(0, 7)}`

  return (
    <NextHead>
      <title>{title || defaultTitle}</title>
      <meta key="description" name="description" content={description || defaultDescription}></meta>
      <meta key="og:title" property="og:title" content={title || defaultTitle} />
      <meta
        key="og:description"
        property="og:description"
        content={description || defaultDescription}
      />
      <meta key="og:type" property="og:type" content="website" />
      <meta key="og:url" property="og:url" content={url} />
      <meta key="og:image" property="og:image" content={ogImage} />
      <meta key="os:site_name" property="og:site_name" content={defaultTitle} />
      <meta key="twitter:card" name="twitter:card" content="summary" />
      <meta key="twitter:url" name="twitter:url" content={url} />
      <meta key="twitter:title" name="twitter:title" content={title || defaultTitle} />
      <meta
        key="twitter:description"
        name="twitter:description"
        content={description || defaultDescription}
      />
      <meta key="twitter:image" name="twitter:image" content={ogImage} />
      <link rel="apple-touch-icon" sizes="192x192" href="/images/icon.png"></link>
      <link rel="icon" sizes="192x192" href="/images/icon.png" />
      <meta name="theme-color" content="#677f75" />
      <link rel="shortcut icon" type="image/png" href={favicon} />
      <link rel="canonical" href={`${url}/`} />
    </NextHead>
  )
}
